import moment from "moment";

/**
 * initialData: object - The initial data for the registration form.
 */
export const initialData = {
  first_name: "",
  last_name: "",
  guardian_email: "",
  email: "",
  birthday: "",
  group_keys: "",
  street: "",
  street_number: "",
  zip_code: "",
  city: "",
  password: "",
  salutation: "",
  confirmPassword: "",
};

/**
 * Checks if the user needs a guardian based on the minimum age.
 * @param birthday: string - The birthday of the user.
 * @returns
 */
export const userNeedsAGuardian = (birthday: string, minimumAge: number) => {
  const userAge = moment().diff(moment(birthday, "YYYY-MM-DD"), "years");
  if (userAge < minimumAge) {
    return true;
  }
  return false;
};
