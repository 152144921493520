import { Dispatch, SetStateAction } from "react";
import { AddMembershipDocumentForm } from "../profile-settings/membership-documents/AddMembershipDocumentForm";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import { useTranslation } from "react-i18next";
import { FileObject } from "material-ui-dropzone";

interface GroupMembershipDocumentSectionProps {
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

export const GroupMembershipDocumentSection: React.FC<
  GroupMembershipDocumentSectionProps
> = (props) => {
  const { groupMembershipDocument, setGroupMembershipDocument, files, setFiles } = props;

  const { t } = useTranslation(["authentication"]);

  return (
    <>
      <div className="RegistrationAdress" style={{ width: "100%" }}>
        <h3>{t("registration.targetGroupDiscounts", { ns: "authentication" })}</h3>
      </div>
      <AddMembershipDocumentForm
        isRegistrationPage
        setGroupMembershipDocument={setGroupMembershipDocument}
        groupMembershipDocument={groupMembershipDocument}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};
