/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

interface MaintenanceTime {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

const now = new Date();

/**
 * Formats the time string to ensure it is always two digits.
 * @param time - The time to format
 * @returns A formatted time string
 */
const formatTimeString = (time: number): string => {
  return time.toString().padStart(2, "0");
};

/**
 * Extracts and formats the date and time from a Date object.
 * @param date - The date to extract and format
 * @returns An object containing the formatted date and time
 */
const extractDateTime = (date: Date) => {
  return {
    date: `${formatTimeString(date.getDay())}.${formatTimeString(date.getMonth())}.${date.getFullYear()}`,
    time: `${formatTimeString(date.getHours())}:${formatTimeString(date.getMinutes())}`,
  };
};
/**
 * MaintenanceBanner Component
 *
 * This component displays a banner when the site is in maintenance.
 *
 * @returns {JSX.Element} The rendered component
 */
const MaintenanceBanner: React.FC = () => {
  const { t } = useTranslation(["layout"]);
  const banner = useSelector((state: AppState) => state.maintenance);

  const [siteInMaintenance, setSiteInMaintenance] = useState<boolean>(false);
  const [maintenanceTime, setMaintenanceTime] = useState<MaintenanceTime>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  /**
   * Checks if the site is in maintenance and sets the maintenance time.
   * @returns {void}
   */
  useEffect(() => {
    if (banner.maintenanceBanner.length > 0) {
      if (
        new Date(banner.maintenanceBanner[0].display_banner_start) <= now &&
        new Date(banner.maintenanceBanner[0].display_banner_end) >= now
      ) {
        setSiteInMaintenance(true);

        const start = extractDateTime(
          new Date(banner.maintenanceBanner[0].maintenance_start)
        );
        const end = extractDateTime(
          new Date(banner.maintenanceBanner[0].maintenance_end)
        );
        setMaintenanceTime({
          startDate: start.date,
          startTime: start.time,
          endDate: end.date,
          endTime: end.time,
        });
      }
    }
  }, [banner]);

  return (
    <>
      {siteInMaintenance ? (
        <Alert
          severity="info"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {t("maintenanceBanner", {
            dateStart: maintenanceTime.startDate,
            timeStart: maintenanceTime.startTime,
            dateEnd: maintenanceTime.endDate,
            timeEnd: maintenanceTime.endTime,
          })}
        </Alert>
      ) : null}
    </>
  );
};

export default MaintenanceBanner;

// TODO handle formatting of date depending on locale
