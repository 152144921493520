import { Box, Button, Collapse, Grid, Hidden } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormikContext } from "formik"; //Field,
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelectArrow } from "../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { EventFilterData } from "../../../../models/eventData";
import { AppState } from "../../../../redux";
import { getTargetGroups } from "../../../../redux/target-groups/actions";
import CustomSearchField from "../../../forms/CustomSearchField";
import { useTranslation } from "react-i18next";

const EventFilterForm: React.FC = () => {
  const { values } = useFormikContext<EventFilterData>();
  const { t } = useTranslation(["common", "enums", "events", "snackbars"]);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (
      !targetGroups.isLoading &&
      !targetGroups.targetGroupsLoaded &&
      !targetGroups.isError
    ) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  return (
    <>
      <Collapse in={expanded || !isXS} collapsedSize={0}>
        <Box py={mdDown ? 2 : 0}>
          <Grid container spacing={mdDown ? 2 : 4}>
            <Grid item xs={12} sm={12} style={{ marginBottom: "5rem" }}>
              <CustomSearchField
                name="search"
                id="search"
                label={t("eventFilter.keyword", { ns: "events" })}
                initialValue={values.search}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Hidden smUp>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <Box m={1} display="flex" justifyContent="center">
                <Box px={1}> {t("eventFilter.hideOptions", { ns: "events" })} </Box>
                <CustomSelectArrow className="event-item-shrink-icon" />
              </Box>
            ) : (
              <Box m={1} display="flex" justifyContent="center">
                <Box px={1}> {t("eventFilter.showOptions", { ns: "events" })} </Box>
                <CustomSelectArrow className="event-item-expand-icon" />
              </Box>
            )}
          </Button>
        </Box>
      </Hidden>
    </>
  );
};

export default EventFilterForm;
