import {
  Badge,
  Button,
  ClickAwayListener,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Slide,
  Box,
} from "@material-ui/core";
import "./Cart.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import HeadingLumos from "../../theming/HeadingLumos";
import { LumosCartSvg } from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import CartList from "./CartList";
import routes from "../../../routing/routes";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { getCart } from "../../../redux/cart/actions";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmptyCartButton from "./EmptyCartButton";
import { useTranslation } from "react-i18next";
import { defaultPalette } from "../../theming/defaultTheme";

const Cart: React.FC = () => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const cart = useSelector((state: AppState) => state.cart);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);
  const dispatch = useDispatch();

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cart.cartInitialLoaded, dispatch, isUserLoggedIn]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (open && sm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [open, sm]);

  return (
    <>
      <Box
        component={"div"}
        className={
          accessibility.monoMode ? "shopping-cart-icon-mono" : "shopping-cart-icon"
        }
        sx={{
          bgcolor: defaultPalette.primary?.main,
        }}
      >
        <Badge
          badgeContent={cart?.cart?.cart_items?.length}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          overlap="rectangular"
        >
          <IconButton aria-label="cart" onClick={handleClick("bottom-end")}>
            <LumosCartSvg />
          </IconButton>
        </Badge>
      </Box>

      {[
        <Paper className={"shopping-cart-wrapper"} elevation={3}>
          <Box>
            <HeadingLumos>{t("cart.header", { ns: "booking" })}</HeadingLumos>
            <CartList />
            <Box component={"div"} className={"shopping-cart-bookingbutton-container"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <EmptyCartButton cartList={cart?.cart?.cart_items} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    id="close"
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={handleClick("bottom-end")}
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("forms.buttons.close", { ns: "common" })}
                  </Button>
                </Grid>
                {cart?.cart?.cart_items?.length > 0 && (
                  <Grid item xs={12} sm={4}>
                    <Button
                      id="to-checkout"
                      size="medium"
                      variant="contained"
                      color="primary"
                      disabled={cart?.cart?.cart_items?.length === 0}
                      onClick={() => {
                        document.body.style.removeProperty("overflow");
                        history.push(routes.checkout);
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {t("cart.toCheckout", { ns: "booking" })}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Paper>,
      ].map((paper) =>
        sm ? (
          <ClickAwayListener
            key={paper.key}
            onClickAway={() => {
              /* intercept touch */
            }}
          >
            <Dialog open={open} fullScreen={xs}>
              {paper}
            </Dialog>
          </ClickAwayListener>
        ) : (
          <Popper
            key={paper.key}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            style={{ zIndex: 600, overflow: "hidden" }}
            transition
          >
            {({ TransitionProps }) => (
              <Slide direction="left" {...TransitionProps} timeout={350}>
                {paper}
              </Slide>
            )}
          </Popper>
        )
      )}
    </>
  );
};

export default Cart;
