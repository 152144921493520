import { UserType } from "../models/enums/userType.enum";
import { ActivitiyHistory } from "../models/mailHistory";
import {
  ForgotPasswordRequestData,
  MoodleBaseData,
  ProfileUpdateRequestData,
  ResetPasswordRequestData,
  UserData,
  UserRegistrationRequestData,
  UserAccountActivationData,
  ResendActivationCodeData,
  ChangePasswordRequestData,
  ReactivationAccountCodeData,
} from "../models/userData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { AuthenticationStatus } from "./authStatus";
import { ApiResponse } from "./apiResponse";

export class UsersService extends BaseCrudService {
  // was moved into userService because of the session is tied to the user
  async checkCurrentSession() {
    const response = await this.getAll<AuthenticationStatus>(apiRoutes.session);
    return response;
  }
  async getCurrentUser() {
    const response = await this.getAll<UserData>(apiRoutes.user);
    return response.data;
  }

  async getUsers(search: string | null, user_type: UserType | null, eventId: string) {
    let data = {};
    if (search) data = { search: search };
    if (user_type) data = { ...data, user_type: user_type, event: eventId };

    const response = await this.getAll<UserData[]>(apiRoutes.users, data);
    return response.data;
  }

  async getUser(id: number) {
    const response = await this.get<UserData>(apiRoutes.users, id.toString());
    return response.data;
  }

  async getStatistics(filter: string) {
    const response = await this.getAll<ActivitiyHistory[]>(
      apiRoutes.statistics + filter
    );
    return response.data;
  }

  async getStatistic(id: string) {
    const response = await this.get<ActivitiyHistory>(apiRoutes.statistics, id);
    return response.data;
  }

  // TODO: Should be removed?
  async fetchMoodleData(id: number) {
    const response = await this.get<MoodleBaseData>(
      `${apiRoutes.events}`,
      `${id}${apiRoutes.moodle_data}`
    );
    return response.data;
  }

  // TODO: Should be removed?
  async loginUserInMoodle(id: number) {
    const response = await this.get(
      `${apiRoutes.events}`,
      `${id}${apiRoutes.moodle_login}`
    );
    return response;
  }

  updateProfile(data: ProfileUpdateRequestData) {
    return this.axiosInstance.patch(apiRoutes.profile, data);
  }

  /**
   *  Register user
   *
   * @returns boolean true on success, otherwise false
   */
  async register(data: UserRegistrationRequestData) {
    try {
      await this.create<UserRegistrationRequestData>(apiRoutes.registration, data);

      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Activate user account
   *
   * @returns boolean true on success, otherwise false
   */
  async activate(data: UserAccountActivationData) {
    try {
      await this.create<UserAccountActivationData>(apiRoutes.activate_account, data);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Send mail with reactivation link
   *
   * @returns boolean true on success, otherwise false
   */
  async reactivate(data: ReactivationAccountCodeData) {
    try {
      await this.create<ReactivationAccountCodeData>(apiRoutes.reactivate_account, data);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Send mail with activation link
   *
   * @returns boolean true on success, otherwise false
   */
  async resend_activation_code(data: ResendActivationCodeData) {
    try {
      await this.create<ResendActivationCodeData>(
        apiRoutes.resend_activation_code,
        data
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async deactivateAccount() {
    try {
      await this.getAll<ApiResponse>(apiRoutes.deactivate_account);
      return true;
    } catch (e) {
      return false;
    }
  }

  async forgotPassword(data: ForgotPasswordRequestData) {
    try {
      await this.create<ForgotPasswordRequestData>(apiRoutes.forgot_password, data);

      return true;
    } catch (e) {
      return false;
    }
  }

  async resetPassword(data: ResetPasswordRequestData) {
    try {
      const response = await this.create<ResetPasswordRequestData>(
        apiRoutes.reset_password,
        data
      );
      return response?.status === 200;
    } catch (e) {
      return false;
    }
  }

  async changePassword(data: ChangePasswordRequestData) {
    try {
      const response = await this.create(apiRoutes.change_password, data);
      return {
        ok: response?.status === 200,
      };
    } catch (e) {
      return {
        ok: false,
      };
    }
  }
}
