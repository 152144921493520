export type MembershipDocumentType =
  | "school_student_card"
  | "university_student_card"
  | "certificate_of_disability";

export type DocumentStatusType = "pending" | "approved" | "expired" | "rejected";

export interface MembershipDocumentRequestDto {
  id?: string;
  file: File | string;
  valid_until: string | null;
  document_has_no_expiry_date: boolean;
  document_type: MembershipDocumentType | string;
}

export interface MembershipDocumentReponseDto {
  id: string;
  user: number;
  file: string;
  valid_until: string;
  document_type: MembershipDocumentType;
  document_status: DocumentStatusType;
  file_extension?: string;
  document_has_no_expiry_date: boolean;
}

export interface GroupMembership {
  id?: string;
  user: number;
  document: MembershipDocumentReponseDto;
}

export function getLableForMembershipDocumentType(type: MembershipDocumentType): string {
  switch (type) {
    case "school_student_card":
      return "membershipOptions.schoolStudentCard";
    case "university_student_card":
      return "membershipOptions.universityStudentCard";
    case "certificate_of_disability":
      return "membershipOptions.certificateOfDisability";
  }
}
