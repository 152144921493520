// TODO: In case id on backend side changes, update this field
export const DEFAULT_CERTIFICATE_TYPE = {
  id: "4761fa22-d1af-4f12-ab8b-881293429f18",
  certificate_type: "Standard",
  is_default: true,
};

// TODO: In case id on backend side changes, update this field
export const PAYMENT_METHOD_CARD_ID = "b51f11b4-4e46-4bd2-97e4-7dd7723490e1";
export const PAYMENT_METHOD_GIROPAY_ID = "e7ac0778-8989-4260-b044-f217c45aa46c";

export const SCHOOL_STUDENT_CARD_LABEL = "Schulausweis";

// dummy date to avoid errors on changing date values
export const MINIMUM_DUMMY_DATE = new Date(1970, 1, 1);

export const MINIMUM_DUMMY_DATE_STRING = "01.01.1970";
