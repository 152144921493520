import React from "react";
import { Box, Grid, Link } from "@material-ui/core";
import { PRIVACY_URL } from "../../routing/externalUrls";

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <Grid container>
      <Grid item sm={7}>
        <h1>Datenschutzerklärung</h1>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <p>
            Mit dem Absenden des Online-Antragsformulars erkläre ich mich damit
            einverstanden, dass meine personenbezogenen Daten ausschließlich zur
            Identifikation meiner Person, über eine Kommunikation per E-Mail bzw. den{" "}
            <b>von mir gewählten Drittanbieter des jeweiligen Kommunikationstools</b> -
            beispielsweise für Chats, Telefon- oder Videokonferenzen - der Abwicklung der
            Belehrung, der Ausstellung der Bescheinigung und für den eventuellen Versand
            per E-Mail sowie zur Weiterleitung <b>an die Firma amanzii</b> und unter
            Zuhilfenahme eines Sofort-Bezahlungssystems als{" "}
            <b>Online-Bankkunde oder des von mir gewählten Zahlungsdienstleisters</b>{" "}
            erhoben und verarbeitet werden.
          </p>
          <p>
            Sie dürfen ohne eine ausdrückliche Zustimmung durch mich nicht an weitere
            Dritte weitergeleitet werden.
          </p>
          <p>
            <b>
              Mir ist bewusst, dass amanzii keinen Einfluss darauf hat, was mit meinen
              personenbezogenen Daten im Zusammenhang mit der Nutzung der genannten
              Kommunikationstools von Drittanbietern passiert.
            </b>
          </p>
          <p>
            Ferner ist mir bewusst, dass der Versand von E-Mails mit einem Risiko
            verbunden sein könnte. Ich bestätige, dass ich mich ausreichend über diese
            eventuellen Risiken informiert habe. Mein Einverständnis erfolgt freiwillig.
          </p>
          <p>
            Die über mich erhobenen und gespeicherten Daten werden nach Ablauf einer
            Aufbewahrungsfrist von 2,5 Jahren vollständig gelöscht, soweit sie im
            Zugriffsbereich von amanzii liegen. Die Löschung der biometrischen Daten
            erfolgt bereits nach 3 Monaten.
          </p>
          <p>
            Ein Widerruf der Einwilligung ist jederzeit möglich und hat die sofortige
            Beendigung des Vorganges und die Löschung aller Daten zur Folge.
          </p>
          <p>
            Weitere Informationen zum{" "}
            <Link href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
              Datenschutz und zum Datenschutzbeauftragten der Firma amanzii
            </Link>{" "}
            habe ich gelesen.
          </p>
        </Box>
      </Grid>
    </Grid>
  );
};
