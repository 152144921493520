import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import routes from "../../../routing/routes";

/**
 * DashboardPage component redirects the user to the 'my_events' route.
 * @component DashboardPage
 */
export const DashboardPage: React.FC = () => {
  const history = useHistory();

  /**
   * Redirects to the 'my_events' route when the component mounts.
   * @hook useEffect
   */
  useEffect(() => {
    history.push(routes.my_events);
  }, [history]);

  return null;
};
