/* eslint-disable react-hooks/exhaustive-deps, react/jsx-max-depth */
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import { convertHEIC } from "../../../../utils/convertHEIC";

interface MembershipDocumentUploadProps {
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

export const MembershipDocumentUploadArea: React.FC<MembershipDocumentUploadProps> = (
  props
) => {
  const { t } = useTranslation(["participationTypes", "snackbars"]);
  const { enqueueSnackbar } = useSnackbar();

  const { files, setFiles } = props;

  /**
   * Handler for adding files to the document upload.
   * This function handles the addition of files to the document upload component.
   * It checks each added file and converts HEIC files to PNG format before adding them to the state.
   * For non-HEIC files, they are added directly to the state.
   * @param addedFiles An array of FileObject representing the files to be added.
   */
  const handleAdd = async (addedFiles: FileObject[]) => {
    for (const addedFile of addedFiles) {
      const file = addedFile.file;

      if (file.type === "image/heic" || file.type === "image/heif") {
        const convertedFile = await convertHEIC(file);
        setFiles((prevFiles) => [...prevFiles, convertedFile]);
      } else {
        setFiles((prev) => [...prev, ...addedFiles]);
      }
    }
  };

  return (
    <div
      style={{
        margin: "20px",
      }}
    >
      <Grid item xs={12}>
        <DropzoneAreaBase
          filesLimit={1}
          showAlerts={false}
          fileObjects={files}
          onAdd={handleAdd}
          onDelete={(deletedFile) =>
            setFiles((prev) => prev.filter((file) => file !== deletedFile))
          }
          acceptedFiles={[
            "application/pdf",
            "image/png",
            "image/jpg",
            "image/jpeg",
            ".heic",
            ".heif",
          ]}
          maxFileSize={2 * 1024 * 1024}
          dropzoneText={t("uploadDocNote", { ns: "common" })}
          onDropRejected={() => {
            enqueueSnackbar(t("docFail", { ns: "snackbars" }), {
              variant: "error",
            });
          }}
        />
      </Grid>
    </div>
  );
};
