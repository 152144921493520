import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { elearningService } from "../../../api";
import { enqueueSnackbar } from "notistack";
import { ELearningData } from "../../../models/elearning";
import { AppState } from "../../../redux";
import { initialELearningState } from "../../../redux/e-learnings/reducers";
import { LinkInformation } from "../../../components/core/events/e-learning/newE-learning/E-learningContainer/LinkInformation";
import routes from "../../../routing/routes";
import Loader from "../../../components/theming/loader/Loader";
import "../../../components/core/events/e-learning/newE-learning/Styles/styles.scss";
import { useDispatch } from "react-redux";
import { getMyEvent } from "../../../redux/bookings/actions";

/**
 * ELearningContainerPage component manages the retrieval and display of e-learning content.
 *
 * @component
 * @returns {JSX.Element} - Rendered component.
 */
export const ELearningContainerPage: React.FC = () => {
  const dispatch = useDispatch();

  // Retrieve parameters from the URL
  const { elearning_id, event_participation_id } = useParams<{
    elearning_id: string;
    event_participation_id: string;
  }>();
  // Redux state to check if the user is authenticated
  const { isAuthenticated } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  // Local state for managing e-learning data and fetch status
  const [elearning, setElearning] = useState<ELearningData>(
    initialELearningState.addedELearning
  );
  const [elearningFetched, setElearningFetched] = useState<boolean>(false);
  // Local state for managing participant authentication status and fetch status
  const [participantAuthentication, setParticipantAuthentication] =
    useState<boolean>(true);
  const [participantAuthenticationFetched, setParticipantAuthenticationFetched] =
    useState<boolean>(false);

  const myEvent = useSelector((state: AppState) => state.booking);
  const accessGrantedbyAdmin = myEvent.myEvent.access_granted_by_admin
    ? myEvent.myEvent.access_granted_by_admin
    : false;

  // Fetch participant authentication status
  useEffect(() => {
    // Fetch participant authentication status from the E-Learning service
    async function getElearningParticipantAuthentication() {
      const response =
        await elearningService.getElearningParticipantAuthentication(elearning_id);
      // Update component state with the fetched participant authentication status
      setParticipantAuthentication(response.participant_authentication);
      setParticipantAuthenticationFetched(true);
    }
    // Invoke the function to fetch participant authentication status when the E-Learning ID changes
    getElearningParticipantAuthentication();
  }, [elearning_id]);

  // Fetch e-learning data based on authentication and user status
  useEffect(() => {
    async function getElearning() {
      try {
        // Call the elearningService to retrieve e-learning data
        const response = await elearningService.getElearning(elearning_id);
        // Update the local state with the fetched e-learning data
        setElearning(response);
        // Set e-learning data fetch status to true
        setElearningFetched(true);
        // If an e-learning link is present, delay redirection for a brief display of text
        if (response.elearning_link) {
          setTimeout(() => window.location.replace(response.elearning_link), 3000);
        }
      } catch (error) {
        // Display error message to the user
        enqueueSnackbar(
          `Beim laden der Daten ist ein Fehler aufgetreten: ${error} \n Bitte versuchen Sie es später erneut.!`,
          { variant: "error" }
        );
      }
    }

    // Check if participant authentication status has been fetched
    if (participantAuthenticationFetched) {
      // If participant authentication expected for event, proceed
      if (participantAuthentication) {
        // Check if the user is authenticated before fetching e-learning data
        if (isAuthenticated) {
          getElearning();
        }
      } else {
        // If participant authentication not needed for event, fetch e-learning data
        getElearning();
      }
    }
  }, [
    elearning_id,
    participantAuthentication,
    participantAuthenticationFetched,
    isAuthenticated,
    accessGrantedbyAdmin,
  ]);

  useEffect(() => {
    if (!myEvent.isLoading && !myEvent.myEventLoaded) {
      dispatch(getMyEvent(event_participation_id));
    }
  }, [event_participation_id, myEvent, myEvent.isLoading, myEvent.myEventLoaded]);

  useEffect(() => {
    if (accessGrantedbyAdmin) {
      setParticipantAuthentication(false);
      setParticipantAuthenticationFetched(true);
    }
  }, [accessGrantedbyAdmin]);

  // Display loader while participant authentication status is being fetched
  if (myEvent.isLoading || !myEvent.myEventLoaded) {
    return <Loader />;
  }

  if (!participantAuthenticationFetched && !elearningFetched) {
    return <Loader />;
  }

  // Display LinkInformation component if participant is not authenticated
  if (!participantAuthentication && !accessGrantedbyAdmin) {
    return (
      <LinkInformation
        elearningLink={elearning.elearning_link}
        elearningFetched={elearningFetched}
      />
    );
  }
  // Redirect to authentication, if the participant authentication is expected
  // but participant is not authenticated
  if (participantAuthentication && !isAuthenticated && !accessGrantedbyAdmin) {
    return (
      <Redirect
        to={routes.elearning_authentication_id
          .replace(":id", elearning_id)
          .replace(":event_participation_id", event_participation_id)}
      />
    );
  }
  // Display LinkInformation component if all conditions are met
  return (
    <LinkInformation
      elearningLink={elearning.elearning_link}
      elearningFetched={elearningFetched}
    />
  );
};
