import { Typography } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { AppState } from "..";
import { authService, usersService } from "../../api";
import { ApiHelper } from "../../api/apiHelper";
import { ApiResponse } from "../../api/apiResponse";
import { history } from "../../routing/history";
import routes from "../../routing/routes";
import { AuthResultData, LoginData, MoodleBaseData } from "../../models/userData";
import { showDialog } from "../dialogs/actions";
import { ShowAction } from "../dialogs/types";
import { clearEventList } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearFavoriteList } from "../favorites/actions";
import { FavoriteActionTypes } from "../favorites/types";
import { ResendActivationCodeData } from "../../../src/models/userData";
import { getCurrentUserData } from "../users/actions";
import { UserActionTypes } from "../users/types";
import {
  AuthActionTypes,
  FetchingAuthDataFinishedAction,
  FETCHING_AUTH_DATA_ACTION_FINISHED,
  FetchMoodleDataSuccessAction,
  FETCH_MOODLE_DATA_SUCCESS,
  GetSessionTokenSuccessAction,
  GET_SESSION_TOKEN_SUCCESS,
  LoginFailureAction,
  LoginMoodleSuccessAction,
  LoginSuccessAction,
  LOGIN_FAILURE,
  LOGIN_MOODLE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  LogoutAction,
  MoodleErrorAction,
  MOODLE_ERROR,
  REQUEST,
  RequestAction,
} from "./types";
import { enqueueSnackbar } from "notistack";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";

export function checkLoggedInState() {
  return (
    dispatch: Dispatch<UserActionTypes | AuthActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(authRequest());
    const wasLoggedIn = getState().auth.loggedIn; // check if state was logged in
    return usersService
      .checkCurrentSession()
      .then((response) => {
        if (response?.status === 200 && response.data.isAuthenticated) {
          if (!wasLoggedIn) {
            getCurrentUserData(dispatch);
          }
          dispatch(getSessionsToken());
        } else if (response?.status === 200 && !response.data.isAuthenticated) {
          if (wasLoggedIn) {
            dispatch(loggingOut());
            enqueueSnackbar(getDynamicTranslation("profile.logoutInfo", "snackbars"), {
              variant: "info",
            });
          } else {
            dispatch(fetchingAuthDataFinished());
          }
        }
      })
      .catch(() => {
        dispatch(loggingOut());
      });
  };
}

export function login(data: LoginData, from: any) {
  return (
    dispatch: Dispatch<
      | UserActionTypes
      | AuthActionTypes
      | EventActionTypes
      | FavoriteActionTypes
      | ShowAction
    >
  ) => {
    dispatch(authRequest());
    return authService
      .login(data)
      .then(() => {
        dispatch(loginSuccess(from));
        enqueueSnackbar(getDynamicTranslation("profile.loginSuccess", "snackbars"), {
          variant: "success",
        });
        // !NOTE: clear the event and favorites list after login, to trigger reload, so the user related data is correctly loaded
        // !NOTE: (because the list was loaded before login and does not hold all info, e.g. linked accounts participation status)
        dispatch(clearFavoriteList());
        dispatch(clearEventList());
        // fetch the user data
        getCurrentUserData(dispatch);
      })
      .catch((err: AxiosError<AuthResultData>) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        const hasVmpServiceIsActivated = Object.prototype.hasOwnProperty.call(
          errorObject,
          "vmp_service_is_activated"
        );
        dispatch(loginFailure());
        if (errorStatus === 400) {
          // for 400 status codes show the error message coming from API
          if (errorObject?.redirect_url) {
            // TODO check if still needed, NOTE:NO TRANSLATION YET
            if (errorObject?.redirect_url.includes("login")) {
              dispatch(
                showDialog(
                  {
                    title: "Passwortänderung nötig",
                    message:
                      "Sie müssen vor dem ersten Zugriff auf das Veranstaltungsportal Ihr initiales Passwort in Ihrem LUMOS-Nutzerkonto ändern.",
                    action: () => {
                      window.open(
                        errorObject?.redirect_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                    confirmButtonText: "Passwort ändern",
                  },
                  false,
                  true,
                  "sm"
                )
              );
            } else if (errorObject?.redirect_url.includes("passwort-aendern")) {
              dispatch(
                showDialog(
                  {
                    title: "Initialpasswort abgelaufen",
                    message:
                      "Ihr Initialpasswort ist abgelaufen. Bitte fordern Sie ein neues Passwort an und ändern dieses in Ihrem LUMOS-Nutzerkonto.",
                    action: () => {
                      window.open(
                        errorObject?.redirect_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                    confirmButtonText: "Neues Passwort anfordern",
                  },
                  false,
                  true,
                  "sm"
                )
              );
            } else if (hasVmpServiceIsActivated) {
              if (!errorObject?.vmp_service_is_activated) {
                dispatch(
                  showDialog(
                    {
                      title: "Online-Dienst inaktiv",
                      message: (
                        <Typography>
                          Der Online-Dienst &quot;Veranstaltungsportal&quot; ist nicht
                          aktiviert. Der Dienst kann unter &quot;Konto verwalten&quot; in
                          Ihrem LUMOS-Konto aktiviert werden.
                        </Typography>
                      ),
                      action: () => {
                        if (errorObject?.redirect_url) {
                          window.open(
                            errorObject?.redirect_url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      },
                      confirmButtonText: "Online-Dienst aktivieren",
                    },
                    false,
                    false,
                    "md"
                  )
                );
              }
            }
          } else {
            enqueueSnackbar(ApiHelper.showErrorMessageFromApi(errorObject), {
              variant: "error",
            });
          }
        } else if (errorStatus === 503) {
          // maintenance
          history.push(routes.maintenance);
        } else if (errorObject?.is_active === false && errorObject?.guardian_mail) {
          dispatch(
            showDialog(
              {
                title: getDynamicTranslation(
                  "loginPage.reactivation.title",
                  "authentication"
                ),
                message: getDynamicTranslation(
                  "loginPage.reactivation.guardian",
                  "authentication"
                ),
                action: () => {
                  const userData: ResendActivationCodeData = {
                    email: data.username,
                    reactivate: true,
                  };
                  usersService.resend_activation_code(userData).then((success) => {
                    if (success) {
                      enqueueSnackbar(
                        getDynamicTranslation(
                          "profile.reactivationLinkSend",
                          "snackbars"
                        ),
                        {
                          variant: "success",
                        }
                      );
                    }
                  });
                },
                confirmButtonText: getDynamicTranslation(
                  "loginPage.reactivation.button",
                  "authentication"
                ),
              },
              false,
              false,
              "sm"
            )
          );
        } else if (errorObject?.is_active === false) {
          dispatch(
            showDialog(
              {
                title: getDynamicTranslation(
                  "loginPage.reactivation.title",
                  "authentication"
                ),
                message: getDynamicTranslation(
                  "loginPage.reactivation.noGuardian",
                  "authentication"
                ),
                action: () => {
                  const userData: ResendActivationCodeData = {
                    email: data.username,
                    reactivate: true,
                  };
                  usersService.resend_activation_code(userData).then((success) => {
                    if (success) {
                      enqueueSnackbar(
                        getDynamicTranslation(
                          "profile.reactivationLinkSend",
                          "snackbars"
                        ),
                        {
                          variant: "success",
                        }
                      );
                    }
                  });
                },
                confirmButtonText: getDynamicTranslation(
                  "loginPage.reactivation.button",
                  "authentication"
                ),
              },
              false,
              false,
              "sm"
            )
          );
        } else if (errorObject?.is_banned === true) {
          enqueueSnackbar(getDynamicTranslation("profile.accountBanned", "snackbars"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(getDynamicTranslation("profile.loginError", "snackbars"), {
            variant: "error",
          });
        }
      });
  };
}

export function logout() {
  return (dispatch: Dispatch<LogoutAction>) => {
    return authService
      .logout()
      .then((response: AxiosResponse<ApiResponse>) => {
        history.push(routes.home);
        dispatch(loggingOut());
        enqueueSnackbar(
          `${getDynamicTranslation(
            response.data.detail,
            "snackbars"
          )} ${getDynamicTranslation("profile.logoutDetail", "snackbars")}`,
          { variant: "success" }
        );
      })
      .catch((errorResponse: AxiosError<ApiResponse>) => {
        // use the error message that is coming from backend, if it is undefined use default message
        const errorMessage =
          getDynamicTranslation(errorResponse.response?.data.detail, "snackbars") ??
          getDynamicTranslation("profile.loginFail", "snackbars");
        enqueueSnackbar(errorMessage, { variant: "error" });
      });
  };
}

export function fetchMoodleData(eventId: number) {
  return (dispatch: Dispatch<FetchMoodleDataSuccessAction | MoodleErrorAction>) => {
    return usersService
      .fetchMoodleData(eventId)
      .then((response: MoodleBaseData) => {
        dispatch(fetchMoodleDataSuccess(response));
      })
      .catch(() => {
        dispatch(moodleError());
      });
  };
}

export function loginToMoodle(eventId: number) {
  return (dispatch: Dispatch<LoginMoodleSuccessAction | MoodleErrorAction>) => {
    return usersService
      .loginUserInMoodle(eventId)
      .then((response) => {
        if (response?.status === 204) {
          dispatch(moodleLoginSuccess());
          // login was successful
        }
      })
      .catch(() => {
        dispatch(moodleError());
      });
  };
}

export function loginSuccess(from?: any): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    from,
  };
}

export function fetchMoodleDataSuccess(
  data: MoodleBaseData
): FetchMoodleDataSuccessAction {
  return {
    type: FETCH_MOODLE_DATA_SUCCESS,
    data: data,
  };
}

export function moodleLoginSuccess(): LoginMoodleSuccessAction {
  return {
    type: LOGIN_MOODLE_SUCCESS,
  };
}

export function moodleError(): MoodleErrorAction {
  return {
    type: MOODLE_ERROR,
  };
}

export function authRequest(): RequestAction {
  return {
    type: REQUEST,
  };
}

export function loginFailure(): LoginFailureAction {
  return {
    type: LOGIN_FAILURE,
  };
}

export function getSessionsToken(): GetSessionTokenSuccessAction {
  return {
    type: GET_SESSION_TOKEN_SUCCESS,
  };
}

export function loggingOut(): LogoutAction {
  return { type: LOGOUT };
}

export function fetchingAuthDataFinished(): FetchingAuthDataFinishedAction {
  return { type: FETCHING_AUTH_DATA_ACTION_FINISHED };
}
